<script setup>
const { t } = useI18n();
const { externalLinks, hasLinks } = storeToRefs(useCurrentPlatformStore());

defineProps({
    isActiveMember: Boolean,
});

const footer = ref();

const footerLinks = [
    {
        label: t('pages.dashboard'),
        link: '/members/dashboard',
    },
    {
        label: t('global.calendar'),
        link: '/members/calendar',
    },
    {
        label: t('global.archive'),
        link: '/members/archiv',
    },
    {
        label: t('global.profile'),
        link: '/members/profile',
    },
];
</script>

<template>
    <footer
        ref="footer"
        class="bg-slate-950 sm:py-10 sm:pt-20 py-6"
        data-footer-height
    >
        <div class="container mx-auto">
            <div
                class="flex sm:justify-between flex-col sm:flex-row items-center gap-12 md:gap-4"
            >
                <ul
                    v-if="isActiveMember"
                    class="flex gap-5 items-center flex-col sm:flex-row py-4 sm:py-0"
                >
                    <li v-for="(footer, index) in footerLinks" :key="index">
                        <nuxt-link
                            :to="footer.link"
                            exact-active-class="active text-brand [&>svg]:text-brand"
                        >
                            {{ footer.label }}
                        </nuxt-link>
                    </li>
                </ul>
            </div>
            <div class="flex sm:justify-end justify-center border-t mt-6 pt-6">
                <ul v-if="hasLinks" class="flex gap-5">
                    <li v-if="externalLinks.privacyUrl">
                        <NuxtLink
                            class="text-white"
                            exact-active-class="active text-brand [&>svg]:text-brand"
                            target="_blank"
                            :to="externalLinks.privacyUrl"
                        >
                            {{ $t('global.privacy') }}
                        </NuxtLink>
                    </li>
                    <li v-if="externalLinks.siteNoticeUrl">
                        <NuxtLink
                            class="text-white"
                            exact-active-class="active text-brand [&>svg]:text-brand"
                            target="_blank"
                            :to="externalLinks.siteNoticeUrl"
                        >
                            {{ $t('global.site_notice') }}
                        </NuxtLink>
                    </li>
                    <li v-if="externalLinks.termsUrl">
                        <NuxtLink
                            class="text-white"
                            exact-active-class="active text-brand [&>svg]:text-brand"
                            target="_blank"
                            :to="externalLinks.termsUrl"
                        >
                            {{ $t('global.terms') }}
                        </NuxtLink>
                    </li>
                </ul>
            </div>
        </div>
    </footer>
</template>
