
// @ts-nocheck


export const localeCodes =  [
  "en",
  "de",
  "nl",
  "pl",
  "ro",
  "hu",
  "en-GB",
  "de-DE",
  "nl-NL",
  "pl-PL",
  "ro-RO",
  "hu-HU",
  "de-DE-sfp",
  "en-GB-sfp",
  "de-DE-sap",
  "en-GB-sap",
  "de-DE-lms",
  "en-GB-lms"
]

export const localeLoaders = {
  "en": [{ key: "../lang/en/en.js", load: () => import("../lang/en/en.js?hash=fb4307a4&locale=en" /* webpackChunkName: "locale__var_www_html_lang_en_en_js" */), cache: false }],
  "de": [{ key: "../lang/de/de.js", load: () => import("../lang/de/de.js?hash=8fe06ba0&locale=de" /* webpackChunkName: "locale__var_www_html_lang_de_de_js" */), cache: false }],
  "nl": [{ key: "../lang/de/de.js", load: () => import("../lang/de/de.js?hash=8fe06ba0&locale=de" /* webpackChunkName: "locale__var_www_html_lang_de_de_js" */), cache: false },
{ key: "../lang/nl/nl.js", load: () => import("../lang/nl/nl.js?hash=b818ad87&locale=nl" /* webpackChunkName: "locale__var_www_html_lang_nl_nl_js" */), cache: false }],
  "pl": [{ key: "../lang/de/de.js", load: () => import("../lang/de/de.js?hash=8fe06ba0&locale=de" /* webpackChunkName: "locale__var_www_html_lang_de_de_js" */), cache: false },
{ key: "../lang/pl/pl.js", load: () => import("../lang/pl/pl.js?hash=569c1e0a&locale=pl" /* webpackChunkName: "locale__var_www_html_lang_pl_pl_js" */), cache: false }],
  "ro": [{ key: "../lang/de/de.js", load: () => import("../lang/de/de.js?hash=8fe06ba0&locale=de" /* webpackChunkName: "locale__var_www_html_lang_de_de_js" */), cache: false },
{ key: "../lang/ro/ro.js", load: () => import("../lang/ro/ro.js?hash=c49c74a2&locale=ro" /* webpackChunkName: "locale__var_www_html_lang_ro_ro_js" */), cache: false }],
  "hu": [{ key: "../lang/de/de.js", load: () => import("../lang/de/de.js?hash=8fe06ba0&locale=de" /* webpackChunkName: "locale__var_www_html_lang_de_de_js" */), cache: false },
{ key: "../lang/hu/hu.js", load: () => import("../lang/hu/hu.js?hash=a906390a&locale=hu" /* webpackChunkName: "locale__var_www_html_lang_hu_hu_js" */), cache: false }],
  "en-GB": [{ key: "../lang/en/en.js", load: () => import("../lang/en/en.js?hash=fb4307a4&locale=en" /* webpackChunkName: "locale__var_www_html_lang_en_en_js" */), cache: false }],
  "de-DE": [{ key: "../lang/de/de.js", load: () => import("../lang/de/de.js?hash=8fe06ba0&locale=de" /* webpackChunkName: "locale__var_www_html_lang_de_de_js" */), cache: false }],
  "nl-NL": [{ key: "../lang/de/de.js", load: () => import("../lang/de/de.js?hash=8fe06ba0&locale=de" /* webpackChunkName: "locale__var_www_html_lang_de_de_js" */), cache: false },
{ key: "../lang/nl/nl.js", load: () => import("../lang/nl/nl.js?hash=b818ad87&locale=nl" /* webpackChunkName: "locale__var_www_html_lang_nl_nl_js" */), cache: false }],
  "pl-PL": [{ key: "../lang/de/de.js", load: () => import("../lang/de/de.js?hash=8fe06ba0&locale=de" /* webpackChunkName: "locale__var_www_html_lang_de_de_js" */), cache: false },
{ key: "../lang/pl/pl.js", load: () => import("../lang/pl/pl.js?hash=569c1e0a&locale=pl" /* webpackChunkName: "locale__var_www_html_lang_pl_pl_js" */), cache: false }],
  "ro-RO": [{ key: "../lang/de/de.js", load: () => import("../lang/de/de.js?hash=8fe06ba0&locale=de" /* webpackChunkName: "locale__var_www_html_lang_de_de_js" */), cache: false },
{ key: "../lang/ro/ro.js", load: () => import("../lang/ro/ro.js?hash=c49c74a2&locale=ro" /* webpackChunkName: "locale__var_www_html_lang_ro_ro_js" */), cache: false }],
  "hu-HU": [{ key: "../lang/de/de.js", load: () => import("../lang/de/de.js?hash=8fe06ba0&locale=de" /* webpackChunkName: "locale__var_www_html_lang_de_de_js" */), cache: false },
{ key: "../lang/hu/hu.js", load: () => import("../lang/hu/hu.js?hash=a906390a&locale=hu" /* webpackChunkName: "locale__var_www_html_lang_hu_hu_js" */), cache: false }],
  "de-DE-sfp": [{ key: "../lang/de/de.js", load: () => import("../lang/de/de.js?hash=8fe06ba0&locale=de" /* webpackChunkName: "locale__var_www_html_lang_de_de_js" */), cache: false }],
  "en-GB-sfp": [{ key: "../lang/en/en.js", load: () => import("../lang/en/en.js?hash=fb4307a4&locale=en" /* webpackChunkName: "locale__var_www_html_lang_en_en_js" */), cache: false }],
  "de-DE-sap": [{ key: "../lang/de/de.js", load: () => import("../lang/de/de.js?hash=8fe06ba0&locale=de" /* webpackChunkName: "locale__var_www_html_lang_de_de_js" */), cache: false }],
  "en-GB-sap": [{ key: "../lang/en/en.js", load: () => import("../lang/en/en.js?hash=fb4307a4&locale=en" /* webpackChunkName: "locale__var_www_html_lang_en_en_js" */), cache: false }],
  "de-DE-lms": [{ key: "../lang/de/de.js", load: () => import("../lang/de/de.js?hash=8fe06ba0&locale=de" /* webpackChunkName: "locale__var_www_html_lang_de_de_js" */), cache: false }],
  "en-GB-lms": [{ key: "../lang/en/en.js", load: () => import("../lang/en/en.js?hash=fb4307a4&locale=en" /* webpackChunkName: "locale__var_www_html_lang_en_en_js" */), cache: false }]
}

export const vueI18nConfigs = [
  
]

export const nuxtI18nOptions = {
  "experimental": {
    "localeDetector": "",
    "switchLocalePathLinkSSR": false,
    "autoImportTranslationFunctions": false
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": true,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "",
  "locales": [
    {
      "code": "en",
      "files": [
        "lang/en/en.js"
      ]
    },
    {
      "code": "de",
      "files": [
        "lang/de/de.js"
      ]
    },
    {
      "code": "nl",
      "files": [
        "lang/de/de.js",
        "lang/nl/nl.js"
      ]
    },
    {
      "code": "pl",
      "files": [
        "lang/de/de.js",
        "lang/pl/pl.js"
      ]
    },
    {
      "code": "ro",
      "files": [
        "lang/de/de.js",
        "lang/ro/ro.js"
      ]
    },
    {
      "code": "hu",
      "files": [
        "lang/de/de.js",
        "lang/hu/hu.js"
      ]
    },
    {
      "code": "en-GB",
      "iso": "en-GB",
      "name": "English (UK)",
      "files": [
        "lang/en/en.js"
      ]
    },
    {
      "code": "de-DE",
      "iso": "de-DE",
      "name": "Deutsch",
      "files": [
        "lang/de/de.js"
      ]
    },
    {
      "code": "nl-NL",
      "iso": "nl-NL",
      "name": "Nederlands",
      "files": [
        "lang/de/de.js",
        "lang/nl/nl.js"
      ]
    },
    {
      "code": "pl-PL",
      "iso": "pl-PL",
      "name": "Polski",
      "files": [
        "lang/de/de.js",
        "lang/pl/pl.js"
      ]
    },
    {
      "code": "ro-RO",
      "iso": "ro-RO",
      "name": "Română",
      "files": [
        "lang/de/de.js",
        "lang/ro/ro.js"
      ]
    },
    {
      "code": "hu-HU",
      "iso": "hu-HU",
      "name": "Magyar",
      "files": [
        "lang/de/de.js",
        "lang/hu/hu.js"
      ]
    },
    {
      "code": "de-DE-sfp",
      "name": "Deutsch (Produktspezifisch sfp)",
      "files": [
        "lang/de/de.js"
      ]
    },
    {
      "code": "en-GB-sfp",
      "name": "English (Produktspezifisch sfp)",
      "files": [
        "lang/en/en.js"
      ]
    },
    {
      "code": "de-DE-sap",
      "name": "Deutsch (Produktspezifisch sap)",
      "files": [
        "lang/de/de.js"
      ]
    },
    {
      "code": "en-GB-sap",
      "name": "English (Produktspezifisch sap)",
      "files": [
        "lang/en/en.js"
      ]
    },
    {
      "code": "de-DE-lms",
      "name": "Deutsch (Produktspezifisch lms)",
      "files": [
        "lang/de/de.js"
      ]
    },
    {
      "code": "en-GB-lms",
      "name": "English (Produktspezifisch lms)",
      "files": [
        "lang/en/en.js"
      ]
    }
  ],
  "defaultLocale": "en",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "no_prefix",
  "lazy": true,
  "langDir": "lang",
  "detectBrowserLanguage": {
    "alwaysRedirect": false,
    "cookieCrossOrigin": false,
    "cookieDomain": null,
    "cookieKey": "i18n_redirected",
    "cookieSecure": false,
    "fallbackLocale": "",
    "redirectOn": "root",
    "useCookie": true
  },
  "differentDomains": false,
  "baseUrl": "",
  "dynamicRouteParams": false,
  "customRoutes": "page",
  "pages": {},
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "i18nModules": []
}

export const normalizedLocales = [
  {
    "code": "en",
    "files": [
      {
        "path": "lang/en/en.js"
      }
    ]
  },
  {
    "code": "de",
    "files": [
      {
        "path": "lang/de/de.js"
      }
    ]
  },
  {
    "code": "nl",
    "files": [
      {
        "path": "lang/de/de.js"
      },
      {
        "path": "lang/nl/nl.js"
      }
    ]
  },
  {
    "code": "pl",
    "files": [
      {
        "path": "lang/de/de.js"
      },
      {
        "path": "lang/pl/pl.js"
      }
    ]
  },
  {
    "code": "ro",
    "files": [
      {
        "path": "lang/de/de.js"
      },
      {
        "path": "lang/ro/ro.js"
      }
    ]
  },
  {
    "code": "hu",
    "files": [
      {
        "path": "lang/de/de.js"
      },
      {
        "path": "lang/hu/hu.js"
      }
    ]
  },
  {
    "code": "en-GB",
    "iso": "en-GB",
    "name": "English (UK)",
    "files": [
      {
        "path": "lang/en/en.js"
      }
    ]
  },
  {
    "code": "de-DE",
    "iso": "de-DE",
    "name": "Deutsch",
    "files": [
      {
        "path": "lang/de/de.js"
      }
    ]
  },
  {
    "code": "nl-NL",
    "iso": "nl-NL",
    "name": "Nederlands",
    "files": [
      {
        "path": "lang/de/de.js"
      },
      {
        "path": "lang/nl/nl.js"
      }
    ]
  },
  {
    "code": "pl-PL",
    "iso": "pl-PL",
    "name": "Polski",
    "files": [
      {
        "path": "lang/de/de.js"
      },
      {
        "path": "lang/pl/pl.js"
      }
    ]
  },
  {
    "code": "ro-RO",
    "iso": "ro-RO",
    "name": "Română",
    "files": [
      {
        "path": "lang/de/de.js"
      },
      {
        "path": "lang/ro/ro.js"
      }
    ]
  },
  {
    "code": "hu-HU",
    "iso": "hu-HU",
    "name": "Magyar",
    "files": [
      {
        "path": "lang/de/de.js"
      },
      {
        "path": "lang/hu/hu.js"
      }
    ]
  },
  {
    "code": "de-DE-sfp",
    "name": "Deutsch (Produktspezifisch sfp)",
    "files": [
      {
        "path": "lang/de/de.js"
      }
    ]
  },
  {
    "code": "en-GB-sfp",
    "name": "English (Produktspezifisch sfp)",
    "files": [
      {
        "path": "lang/en/en.js"
      }
    ]
  },
  {
    "code": "de-DE-sap",
    "name": "Deutsch (Produktspezifisch sap)",
    "files": [
      {
        "path": "lang/de/de.js"
      }
    ]
  },
  {
    "code": "en-GB-sap",
    "name": "English (Produktspezifisch sap)",
    "files": [
      {
        "path": "lang/en/en.js"
      }
    ]
  },
  {
    "code": "de-DE-lms",
    "name": "Deutsch (Produktspezifisch lms)",
    "files": [
      {
        "path": "lang/de/de.js"
      }
    ]
  },
  {
    "code": "en-GB-lms",
    "name": "English (Produktspezifisch lms)",
    "files": [
      {
        "path": "lang/en/en.js"
      }
    ]
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false

export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18n"
export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
