<script setup>
import { NuxtLink } from '#components';
import { defu } from 'defu';

const props = defineProps({
    linkTo: {
        type: [String, Object],
    },
    hrefTo: {
        type: String,
    },

    // Types
    primary: Boolean,
    white: Boolean,
    outline: Boolean,
    outlineWhite: Boolean,
    small: Boolean,
    xsmall: Boolean,
    large: Boolean,
    disabled: Boolean,
    link: Boolean,
    red: Boolean,

    // icons
    onlyIcon: Boolean,
    icon: Boolean,
    arrow: Boolean,
    arrowAfter: Boolean,
    plus: Boolean,
    loading: Boolean,
});

const elAttrs = useAttrs();

const tagName = computed(() => {
    if (props.linkTo) {
        return NuxtLink;
    }

    if (props.hrefTo) {
        return 'a';
    }

    return 'button';
});

const attrs = computed(() => {
    if (props.linkTo) {
        return {
            to: props.linkTo,
        };
    }

    if (props.hrefTo) {
        return defu(elAttrs.value, {
            href: props.hrefTo,
            target: '_blank',
            rel: 'noopener noreferrer',
        });
    }

    return {};
});
</script>

<template>
    <component
        :is="tagName"
        class="btn"
        :class="{
            'has-icon': icon,
            'btn-small': small,
            'btn-extra-small': xsmall,
            'btn-large': large,
            'btn-primary': primary,
            'btn-white': white,
            'btn-outline': outline,
            'btn-outline-white': outlineWhite,
            'btn-link': link,
            'btn-red': red,
            'only-icon': onlyIcon,
            disabled: disabled,
        }"
        v-bind="attrs"
    >
        <CommonLoadingSpinner
            v-if="loading"
            class="h-5 w-5 spinner-icon"
            :class="{ '!h-3 !w-3': xsmall || small }"
        />

        <IconArrowNext
            v-else-if="icon && arrow"
            :class="{ '!h-3 !w-3': xsmall }"
        />

        <slot v-if="!loading" name="custom-icon"/>

        <slot/>

        <div class="custom-icon-after">
            <slot name="custom-icon-after"/>
        </div>

        <IconArrowNext
            v-if="icon && arrowAfter"
            class="stroke-current icon-after"
        />
    </component>
</template>
