<script setup>
const { data: profile } = useAuth();

const props = defineProps({
    logo: {
        type: Object,
    },
    src: {
        type: String,
    },
    alt: {
        type: String,
    },
    size: {
        type: String,
        default: 'small',
        validator: (value) => {
            return ['small', 'medium', 'large'].includes(value);
        },
    },
    bg_color: {
        type: String,
        default: 'dark',
        validator: (value) => {
            return ['dark', 'light', 'transparent'].includes(value);
        },
    },
    isProfileView: {
        type: Boolean,
        default: false,
    },
});

const bgColorClass = computed(() => {
    return `backdrop-${props.bg_color}`;
});
</script>

<template>
    <NuxtLink
        v-if="src || profile?.platform?.logo"
        to="/members/dashboard"
        :class="[
            bgColorClass,
            `backdrop-${size}`,
            isProfileView ? 'profile-view' : '',
        ]"
        class="-mt-[calc(1.5rem+1px)] md:-mt-[calc(2rem+1px)] xl:-mt-[calc(3rem+1px)]"
    >
        <CommonPolledImage
            v-if="isProfileView"
            :src="src || profile?.platform?.logo?.src"
            :alt="alt || profile?.platform?.name"
            :class="`size-${props.size}`"
            class="object-contain"
        />
        <img
            v-else
            :src="src || profile?.platform?.logo?.src"
            :alt="alt || profile?.platform?.name"
            :class="`size-${props.size}`"
            class="object-contain"
        />
    </NuxtLink>
</template>

<style scoped>
.backdrop-dark {
    @apply bg-black/60 hover:bg-black/70 transition-colors shadow-xl backdrop-blur-sm;
}

.backdrop-light {
    @apply bg-white/60 hover:bg-white/70 transition-colors shadow-xl backdrop-blur-sm;
}

.backdrop-transparent {
    @apply bg-transparent transition-colors !px-0 !pb-0;

    &.profile-view {
        @apply !pt-0;
    }
}

.backdrop-small {
    @apply pt-6 p-4 pb-3 md:pb-5 md:p-6 md:pt-7 xl:pt-12 xl:pb-6 xl:px-8 rounded-b-lg;

    &.profile-view {
        @apply p-4;
    }
}

.backdrop-medium {
    @apply pt-7 p-5 pb-4 md:pb-6 md:p-7 md:pt-8 xl:pb-8 xl:pt-14 xl:px-10 rounded-b-lg;

    &.profile-view {
        @apply p-5;
    }
}

.backdrop-large {
    @apply pt-7 p-6 pb-5 md:pb-6 md:p-8 md:pt-10 xl:pt-16 xl:pb-9 xl:px-12 rounded-b-xl;

    &.profile-view {
        @apply p-6;
    }
}

.profile-view {
    @apply !mt-0 !rounded-lg pointer-events-none flex;
}

.size-small {
    @apply h-10 md:h-12 xl:h-16 w-auto max-w-[160px];
}

.size-medium {
    @apply h-12 md:h-14 xl:h-[4.5rem] w-auto max-w-[180px];
}

.size-large {
    @apply h-14 md:h-[4.5rem] xl:h-20 w-auto max-w-[220px];
}
</style>
