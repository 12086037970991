/**
 * Check if the profile has access to the product
 *
 * @param {Object} profile
 * @param {string} product
 * @return {boolean} true if the profile has access to the product
 */
const checkProductManagementAccess = (profile, product) => {
    if (!profile) {
        return false;
    }

    return profile.product_management_access[product] || false;
};

/**
 * Get the manageable products from the profile object
 *
 * @param {Object} profile the profile object from useAuth composable
 */
const getManageableProducts = (profile) => {
    return Object.entries(profile?.product_management_access ?? {})
        .filter(([, value]) => value)
        .map(([key]) => key);
};

/**
 * Get the admin base url from the profile object
 *
 * @param {Object} profile the profile object from useAuth composable
 * @param {string} [team] uuid of the team to be used
 * @param {string} [product] uuid of the product to be used
 * @returns url to the admin dashboard
 */
export const getAdminBaseUrl = (profile, team = null, product = null) => {
    const _profile = unref(profile);

    const manageableProducts = getManageableProducts(_profile);
    const teamUuid =
        team ?? _profile?.last_used_team?.uuid ?? _profile.team.uuid;

    const lastUsedProduct = product ?? _profile?.last_used_product?.uuid;

    const productUuid = checkProductManagementAccess(
        _profile,
        lastUsedProduct ?? ''
    )
        ? lastUsedProduct
        : manageableProducts[0];

    return `/admin/${teamUuid}/${productUuid}`;
};

/**
 * Get the admin dashboard url from the profile object
 *
 * @param {Object} profile the profile object from useAuth composable
 * @param {string} [team] uuid of the team to be used
 * @param {string} [product] uuid of the product to be used
 * @returns url to the admin dashboard
 */
export const getAdminDashboardFromProfile = (
    profile,
    team = null,
    product = null
) => {
    const baseUrl = getAdminBaseUrl(profile, team, product);

    return `${baseUrl}/dashboard`;
};

export const redirectToAdminDashboard = (profile, team, product) => {
    const url = getAdminDashboardFromProfile(profile, team, product);

    return navigateTo(url);
};
