export const appHead = {"meta":[{"name":"viewport","content":"width=device-width, initial-scale=1"},{"charset":"utf-8"},{"name":"msapplication-TileColor","content":"#ffffff"},{"name":"msapplication-TileImage","content":"/favicons/ms-icon-144x144.png"},{"name":"theme-color","content":"#ffffff"}],"link":[{"rel":"icon","type":"image/x-icon","href":"/favicon.ico"},{"rel":"apple-touch-icon","sizes":"57x57","href":"/favicons/apple-icon-57x57.png"},{"rel":"apple-touch-icon","sizes":"60x60","href":"/favicons/apple-icon-60x60.png"},{"rel":"apple-touch-icon","sizes":"72x72","href":"/favicons/apple-icon-72x72.png"},{"rel":"apple-touch-icon","sizes":"76x76","href":"/favicons/apple-icon-76x76.png"},{"rel":"apple-touch-icon","sizes":"114x114","href":"/favicons/apple-icon-114x114.png"},{"rel":"apple-touch-icon","sizes":"120x120","href":"/favicons/apple-icon-120x120.png"},{"rel":"apple-touch-icon","sizes":"144x144","href":"/favicons/apple-icon-144x144.png"},{"rel":"apple-touch-icon","sizes":"152x152","href":"/favicons/apple-icon-152x152.png"},{"rel":"apple-touch-icon","sizes":"180x180","href":"/favicons/apple-icon-180x180.png"},{"rel":"icon","type":"image/png","sizes":"192x192","href":"/favicons/android-icon-192x192.png"},{"rel":"icon","type":"image/png","sizes":"32x32","href":"/favicons/favicon-32x32.png"},{"rel":"icon","type":"image/png","sizes":"96x96","href":"/favicons/favicon-96x96.png"},{"rel":"icon","type":"image/png","sizes":"16x16","href":"/favicons/favicon-16x16.png"},{"rel":"manifest","href":"/manifest.json"}],"style":[],"script":[{"src":"https://5f3c395.ccm19.de/app/public/ccm19.js?apiKey=5d8c70493d06c746b56b4dfc2d0c01dafe9a1e0721f44a6d","referrerpolicy":"origin"},{"src":"https://user-frontend.api.pl-x.cloud/js/widget.js"}],"noscript":[]}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appRootAttrs = {"id":"__nuxt"}

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const appTeleportAttrs = {"id":"teleports"}

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = true

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink"}

export const asyncDataDefaults = {"value":null,"errorValue":null,"deep":true}

export const resetAsyncDataToUndefined = true

export const nuxtDefaultErrorValue = null

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false

export const appId = "nuxt-app"

export const outdatedBuildInterval = 3600000