import revive_payload_client_4sVQNw7RlN from "/var/www/html/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/var/www/html/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/var/www/html/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/var/www/html/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/var/www/html/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/var/www/html/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/var/www/html/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/var/www/html/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/var/www/html/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/var/www/html/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_AOuQ1DYzjk from "/var/www/html/node_modules/@sidebase/nuxt-auth/dist/runtime/plugin.mjs";
import switch_locale_path_ssr_8BKffvaEi5 from "/var/www/html/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.mjs";
import i18n_yfWm7jX06p from "/var/www/html/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import vue_error_reporter_BoCMjtfncl from "/var/www/html/modules/nuxt-sentry/runtime/vue-error-reporter.ts";
import apiFetch_EjbVSSQ2lF from "/var/www/html/plugins/apiFetch.js";
import assetHelperFunctions_client_5gKXjDy6KN from "/var/www/html/plugins/assetHelperFunctions.client.js";
import capabilities_Tcsv3MQevH from "/var/www/html/plugins/capabilities.js";
import echo_E4VcyfBaFS from "/var/www/html/plugins/echo.js";
import eventbus_HoXFXDNDgX from "/var/www/html/plugins/eventbus.js";
import imports_client_OlzWc19XqT from "/var/www/html/plugins/imports.client.js";
import notify_Oxf81SlxaN from "/var/www/html/plugins/notify.js";
import pxUser_Ofsz7ZApBF from "/var/www/html/plugins/pxUser.js";
import userLocale_nQdGkxquHD from "/var/www/html/plugins/userLocale.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_AOuQ1DYzjk,
  switch_locale_path_ssr_8BKffvaEi5,
  i18n_yfWm7jX06p,
  vue_error_reporter_BoCMjtfncl,
  apiFetch_EjbVSSQ2lF,
  assetHelperFunctions_client_5gKXjDy6KN,
  capabilities_Tcsv3MQevH,
  echo_E4VcyfBaFS,
  eventbus_HoXFXDNDgX,
  imports_client_OlzWc19XqT,
  notify_Oxf81SlxaN,
  pxUser_Ofsz7ZApBF,
  userLocale_nQdGkxquHD
]