<script setup>
definePageMeta({
    auth: false,
});

const { data: profile } = useAuth();

defineProps({
    error: Object,
});

const isActiveMember = ref(false);
const footerHeight = ref();
const errorCode = useError();

const statusCode = computed(() => errorCode.value?.statusCode);

const showFooter = computed(() => {
    return statusCode.value === 404;
});

function getFooterHeight() {
    const element = document.querySelector('[data-footer-height]');
    footerHeight.value = `${element.offsetHeight}px`;
}

if (showFooter.value) {
    useWindowEvent('resize', getFooterHeight);
}
</script>

<template>
    <div class="w-full relative">
        <div
            v-if="profile?.team?.hero?.src"
            class="absolute inset-0 before:content-[''] before:absolute before:inset-0 before:bg-slate-800/50"
        >
            <img
                :src="profile?.team?.hero?.src"
                :alt="profile?.team?.name"
                class="w-full h-full object-cover"
            />
        </div>
        <div
            v-else
            class="absolute inset-0 bg-gradient-to-tr from-slate-500 via-slate-700 to-slate-800"
        />

        <MemberAppHeader :is-active-member="isActiveMember" />

        <main
            class="flex items-center justify-center"
            :class="showFooter ? 'main-auth' : 'h-screen'"
        >
            <Error404 v-if="statusCode === 404" />
            <Error401 v-else-if="statusCode === 401" />
            <Error403 v-else-if="statusCode === 403" />
            <Error500 v-else-if="statusCode === 500" />
            <div
                v-else
                class="flex relative items-center max-w-[970px] flex-col p-4"
            >
                <h1
                    class="text-[30px] sm:text-[60px] font-bold text-white leading-6"
                >
                    {{ errorCode?.statusCode }}
                </h1>
                <p class="text-white text-2xl">
                    {{ errorCode?.statusMessage }}
                </p>
            </div>
        </main>
    </div>
    <MemberFooter v-if="showFooter" :is-active-member="isActiveMember">
        <template #logo>
            <IconLogo class="w-[100px] h-[50px]" />
        </template>
    </MemberFooter>
</template>

<style scoped>
.main-auth {
    min-height: calc(100vh - v-bind('footerHeight'));
}
</style>
