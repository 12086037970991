import typeOf from 'just-typeof';

/**
 * Check if user has required roles
 *
 * @param {string|array} capabilities required roles
 * @returns boolean
 */
const resolveRole = (roles) => {
    if (typeOf(roles) === 'string') {
        roles = roles.split('|');
    }

    const { userRoles } = useUserPermissions();

    return (
        userRoles?.value && roles.some((role) => userRoles.value.includes(role))
    );
};

/**
 * Check if user has required capabilities
 *
 * @param {string|array} capabilities required capabilities
 * @returns boolean
 */
// eslint-disable-next-line no-unused-vars
const resolveCapability = (capabilities) => {
    console.error(
        'Not implemented yet. Use $can, $cant or $role directive instead.'
    );
    // if (typeOf(capabilities) === 'string') {
    //     capabilities = capabilities.split('|');
    // }

    // return capabilities.some((capability) => userCapabilities.includes(capability));
};

/**
 * Check if user has required roles or capabilities
 *
 * @param {string|array} rolesOrCapabilities required roles or capabilities
 * @returns boolean
 */
const resolveCan = (rolesOrCapabilities) => {
    const { userRoles, userCapabilities } = useUserPermissions();

    if (typeOf(rolesOrCapabilities) === 'string') {
        rolesOrCapabilities = rolesOrCapabilities.split('|');
    }

    const userRolesAndCapabilities = [
        ...userRoles.value,
        ...userCapabilities.value,
    ];

    return rolesOrCapabilities.some((roleOrCapability) =>
        userRolesAndCapabilities.includes(roleOrCapability)
    );
};

/**
 * Inverse of resolveCan or $can
 *
 * @param {string|array} rolesOrCapabilities required roles or capabilities
 * @returns boolean
 */
const resolveCant = (rolesOrCapabilities) => {
    return !resolveCan(rolesOrCapabilities);
};

export default defineNuxtPlugin((nuxtApp) => {
    // Provide $can, $cant and $role directives
    nuxtApp.provide('can', (rolesOrCapabilities) =>
        resolveCan(rolesOrCapabilities)
    );
    nuxtApp.provide('cant', (rolesOrCapabilities) =>
        resolveCant(rolesOrCapabilities)
    );
    nuxtApp.provide('capability', (capabilities) =>
        resolveCapability(capabilities, [])
    );
    nuxtApp.provide('role', (_roles) => resolveRole(_roles));
});
