<script setup>
const { t } = useI18n();

const props = defineProps({
    errorCode: {
        type: [String, Number],
        default: 500,
    },
    onHandleError: {
        type: Function,
        default: null,
    },
});

/**
 * Safe runtime translation
 *
 * @param {string} key
 */
const safeRt = (key) => {
    try {
        return t(`errors.${props.errorCode}_${key}`);
    } catch (error) {
        return null;
    }
};

const headline = safeRt('headline');
const subline = safeRt('subline');
const paragraph = safeRt('paragraph');
const buttonText = safeRt('buttonText');
</script>

<template>
    <div
        class="flex relative items-center max-w-[970px] flex-col sm:flex-row p-4"
    >
        <h1
            class="text-[80px] sm:text-[160px] font-bold text-white"
            v-text="errorCode"
        />
        <div class="text-white sm:pl-12">
            <h2 class="text-3xl sm:text-5xl leading-10 font-bold">
                {{ headline }}
                <template v-if="subline">
                    <br />
                    {{ subline }}
                </template>
            </h2>
            <p class="font-light pb-4" v-text="paragraph" />
            <CommonButton
                v-if="onHandleError"
                primary
                arrow
                icon
                @click="onHandleError"
            >
                {{ buttonText }}
            </CommonButton>
        </div>
    </div>
</template>
