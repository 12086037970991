import type * as Sentry from '@sentry/node';

const IGNORED_STATUSES = [401, 404, 422, 409, 500];

const beforeSendHook = (event: Sentry.Event, hint?: Sentry.EventHint) => {
    const error = hint?.originalException;

    // Ignore certain statuses
    if (error && error instanceof Response && IGNORED_STATUSES.includes(error.status)) {
        return null;
    }

    return event;

};

export default beforeSendHook;
